import React from 'react';
import { getEntities } from '../reducers/entities';
import { connect } from "react-redux";
import TemplateBuilder from './TemplateBuilder'
import { selectInvoice } from '../reducers/invoices';
import moment from 'moment'
import {
    MDBCol,
    MDBRow,
    MDBInput,
    MDBModal,
    MDBNavLink,
    MDBModalFooter,
    MDBModalBody,
    MDBModalHeader,
    MDBDataTable,
    MDBListGroup, MDBListGroupItem,
    MDBContainer, MDBInputGroup,
    MDBBtn
} from 'mdbreact';

const ClientSelectorComponent = ({ entities, onChangeText, value, viewOnly }) => {

    const [showPicker, showPickerVisible] = React.useState(false);

    var Company = [];

    if (entities && entities.length > 0) {
        Company = entities.map(p => {
            return {
                name: p.name + (p.surname ? ' ' + p.surname : ''),
                view: <button data-test="button" type="button" class="btn btn-secondary" onClick={() => {
                    onChangeText('entityId', p._id);
                    showPickerVisible(false);
                }
                } >Select</button>,
            }
        });
    }

    const data = {
        columns: [
            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: '',
                field: 'view',
                sort: 'asc'
            }
        ],
        rows: Company
    };

    let selectedEntity = entities && entities.find(i => i._id == value);

    return (
        <div>
            <div style={{ flex: 1 }}>
                <div>
                    {viewOnly && selectedEntity && (
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-top">
                                    <div>
                                        <h5 className="card-title mb-1">{selectedEntity.name}</h5>
                                        <div className="text-muted">
                                            {selectedEntity?.phone || selectedEntity?.contactNumber && <div><i className="fas fa-phone mr-2">{' '}{selectedEntity?.contactNumber ?? selectedEntity?.phone}</i></div>}
                                            {selectedEntity?.email && <div><i className="fas fa-envelope mr-2">{' '}{selectedEntity?.email}</i></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!viewOnly && selectedEntity && (
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-top">
                                    <div>
                                        <h5 className="card-title mb-1">{selectedEntity.name}</h5>
                                        <div className="text-muted">
                                            {selectedEntity?.phone || selectedEntity?.contactNumber && <div><i className="fas fa-phone mr-2">{' '}{selectedEntity?.contactNumber ?? selectedEntity?.phone}</i></div>}
                                            {selectedEntity?.email && <div><i className="fas fa-envelope mr-2">{' '}{selectedEntity?.email}</i></div>}
                                        </div>
                                    </div>
                                    <button 
                                        data-test="button" 
                                        type="button" 
                                        className="btn btn-link" 
                                        onClick={() => showPickerVisible(true)}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {!selectedEntity && !viewOnly && (
                        <button data-test="button" type="button" className="btn btn-danger" onClick={() => showPickerVisible(true)}>
                            Select Client
                        </button>
                    )}

                    <MDBModal isOpen={showPicker} toggle={() => showPickerVisible(false)} size="lg">
                        <MDBModalHeader toggle={() => showPickerVisible(false)}>Select Client</MDBModalHeader>
                        <MDBModalBody>
                            <MDBDataTable
                                striped
                                bordered
                                small
                                data={data}
                            />
                        </MDBModalBody>
                    </MDBModal>
                </div>
            </div>
        </div>
    )
};

class ClientSelectorComponentScreen extends React.Component {

    state = {
    }

    componentDidMount() {
        this.props.dispatchGetEntities();
    }

    render() {
        return (
            <ClientSelectorComponent
                {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    entities: state.entities.entities,
})

const mapDispatchToProps = {
    dispatchGetEntities: () => getEntities(),
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelectorComponentScreen)
